<template>
  <div class="product-checkout">
    <div class="product-checkout-image mt-2">
      <img :src="product.product.thumbnail.large" alt="" />
    </div>
    <div class="product-checkout-meta">
      <p class="title" :title="product.product.name">{{ productName }}</p>
      <quantity-input
        :product="product"
        @change-qty="changeQuantity"
        class="mt-1"
      ></quantity-input>
    </div>
    <div class="product-checkout-action">
      <div class="d-flex flex-column align-items-end">
        <font-awesome-icon
          icon="fa-solid fa-trash-alt"
          class="text-primary pointer"
          @click="
            removeProduct(product.id);
            $bvModal.show('product-delete');
          "
        />
        <p class="price">
          {{ formatCurrency(product.prices.price_incl_tax.value) }}
        </p>
      </div>
    </div>

    <!-- badges -->
    <!-- <div class="product-checkout-badges">
            <span class="product-checkout-label label-out" v-if="product.stock_qty === 0">{{ $t('out_of_stock') }}</span>
            <span class="product-checkout-label label-top" v-if="product.top">{{ $t('top') }}</span>
            <span class="product-checkout-label label-sale" v-if="product.sale_price">{{ $t('sale') }}</span>
            <span class="product-checkout-label label-new" v-if="product.new">{{ $t('new') }}</span>
        </div> -->
    <!-- badges -->

    <!-- remove product in cart modal -->
    <b-modal
      id="product-delete"
      class="product-modal-inner"
      centered
      hide-footer
      content-class="defaul-modal"
      size="xl"
    >
      <template #modal-title class="bold">Remove Product</template>
      <div class="modal-text d-block border-bottom text-center">
        <p>Are you sure to remove this product?</p>
      </div>
      <div class="modal-btn-box pt-2">
        <b-button
          class="btn-success"
          block
          @click="
            $bvModal.hide('product-delete');
            removeItem(productID);
          "
          >Ok</b-button
        >
        <b-button
          class="btn-success btn-outline"
          block
          @click="$bvModal.hide('product-delete')"
          >{{ $t("cancel") }}</b-button
        >
      </div>
    </b-modal>
    <!-- remove product in cart modal -->
  </div>
</template>

<script>
import QuantityInput from "@/esf_weert_mobilesupplies/core/components/elements/QuantityInput";
import Cart from "@storefront/core/modules/cart/mixins";

export default {
  props: {
    product: Object,
  },
  data() {
    return {
      qty: 1,
      nameMaxLength: 20,
      productID: "",
    };
  },
  components: {
    QuantityInput,
  },
  mixins: [Cart],
  computed: {
    productName() {
      if (this.product.product.name.length <= this.nameMaxLength) {
        return this.product.product.name;
      } else {
        const words = this.product.product.name.split(" ");
        let shortenedString = "";
        let count = 0;
        for (let i = 0; i < words.length; i++) {
          if (count + words[i].length <= this.nameMaxLength) {
            shortenedString += words[i] + " ";
            count += words[i].length;
          } else {
            break;
          }
        }
        return shortenedString.trim() + "...";
      }
    },
    cartItems() {
      return this.$store.getters["cart/getCartItems"];
    },
  },
  methods: {
    formatCurrency(amount) {
      return this.$helpers.formatCurrency(amount);
    },
    changeQuantity(qty, id) {
      if (qty > 0) {
        this.$store.dispatch("cart/updateCartItem", {
          id: id,
          quantity: qty,
        });
      } else {
        this.$store.dispatch("cart/updateCartItem", {
          id: id,
          quantity: 1,
        });
      }
      this.updateCrossSell();
    },
    updateCrossSell() {
      let skuList = [];
      this.cartItems.forEach((cartItem) => {
        if (cartItem.product && cartItem.product.sku) {
          skuList.push(cartItem.product.sku);
        }
      });
      this.$store.dispatch("product/loadCrossSellForCart", { skus: skuList });
    },
    removeProduct(data) {
      this.productID = data;
      this.updateCrossSell();
    },
  },
};
</script>
